

































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FileUploader from '@/partials/forms/components/FileUploader.vue'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'

type FormTypeTypes = 'radio' | 'check' | 'text'

@Component({
  components: {
    GenericModalDelete,
    ArrowUp16,
    ArrowDown16,
    TrashCan16,
    RichTextEditor,
    PasswordConfirmField,
    NotifyOnFailed,
    FileUploader,
    ValidationProvider,
    ValidationObserver
  }
})
export default class MedicalFormsQuestionForm extends Mixins(FlashMessageMixin, ValidatorConfigMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) formId!: string
  @Prop({ type: String }) questionId!: string

  imageUrl = ''

  form: Record<string, any>= {
    title: '',
    body: '',
    medicalFormId: '',
    attachment: '',
    confirm: '',
    errors: {
      body: '',
      confirm: ''
    }
  }

  deleteModalIsOpened = false

  questionOptions = [] as Array<any>
  questionType: FormTypeTypes = 'radio'

  done = true

  created () {
    this.form.medicalFormId = this.formId
    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        title: 'Titulo da Questão',
        body: 'Questao',
        confirm: ''
      })

      this.questionOptions = [
        { title: '1', key: 1 },
        { title: '2', key: 2 },
        { title: '3', key: 3 }
      ]
    } else if (this.type === 'edit') {
      axios.get(`/medical/form/${this.formId}/question/${this.questionId}`)
        .then(response => {
          const question = camelCaseKeys(response.data.data, { deep: true })

          Object.assign(this.form, {
            title: question.title,
            body: question.body
          })

          this.imageUrl = question.attachment?.publicPath

          this.questionType = question.options[0]?.type

          this.questionOptions = question.options.map((option: any, index: number) => ({
            ...option,
            key: index
          }))
        })
    }
  }

  private submitForm () {
    const options = this.questionType !== 'text'
      ? this.questionOptions.map((option, index) => (
        {
          ...option,
          type: this.questionType,
          order: index
        }))
      : [
        {
          title: 'Resposta',
          type: 'text',
          order: 0
        }
      ]

    return {
      ...this.form,
      options
    }
  }

  submit () {
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit' ? `/medical/form/${this.formId}/question/${this.questionId}` : `/medical/form/${this.formId}/question/`
    this.done = false

    axios[verb](route, snakeCaseKeys(this.submitForm(), { deep: true }))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  redirectOnSuccess () {
    const verb = this.type === 'create' ? 'cadastrada' : 'editada'

    const flashMessage = {
      message: `A Questão foi ${verb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({ name: 'MedicalFormEdit', params: { id: this.formId } }, () => this.setFlashMessage(flashMessage))
  }

  postDeleteSubmit () {
    this.deleteModalIsOpened = false
    this.imageUrl = ''
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number
    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.questionOptions[targetIndex]
    this.questionOptions.splice(targetIndex, 1)
    this.questionOptions.splice(index, 0, swappingChoice)
  }

  removeChoice (index: number) {
    if (this.questionOptions.length > 2) {
      this.questionOptions.splice(index, 1)
    }
  }

  addChoice (index: number) {
    const choice = { title: '', key: this.questionOptions.length + 1 }
    this.questionOptions.splice(index, 0, choice)
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      body: '',
      confirm: ''
    }
  }
}
